<template>
  <div class="row animate__backInRight magazine">
    <div class="col-md-12 mt-2">
      <div class="card">
        <div class="card-body">
          <b-row class="align-items-end">
            <b-col lg="5" md="6" v-if="permFilterVendor()">
              <multiselect
                label="name"
                track-by="name"
                placeholder="Filter by Vendor"
                :close-on-select="false"
                :multiple="true"
                :options="vendorSearchOptions"
                :loading="isVendorSearch"
                v-model="vendor"
                @search-change="vendorFind"
                @input="vendorChange"
              >
                <template slot="clear">
                  <div
                    class="multiselect__clear"
                    v-if="vendor.length"
                    @mousedown.prevent.stop="vendorRemove"
                  ></div>
                </template>
              </multiselect>
            </b-col>
            <b-col cols="8" md="3" class="pt-2 pt-md-0">
              <b-form-select
                v-model="status"
                :options="statusOptions"
                @input="statusChange"
              ></b-form-select>
            </b-col>
            <b-col>
              <b-button @click="onClearSearch">Clear</b-button>
            </b-col>
          </b-row>
          <b-row class="align-items-end py-2">
            <b-col lg="5" md="6">
              <input
                placeholder="Type Here to Search Magazine"
                class="form-control"
                type="search"
                v-model="query_search"
                @input="onSearch"
                v-on:keyup.enter="onSearch"
              />
              <small v-if="typing"> <em> typing ... </em> </small>
            </b-col>
            <b-col class="justify-content-end pt-1 d-flex">
              <span class="pr-2">
                <router-link
                  class="btn btn-sm btn-dark"
                  :to="{ name: MagazineCreatePath.name }"
                >
                  <i class="fa fa-plus"></i>
                </router-link>
              </span>
              <span>
                <button class="btn btn-sm btn-dark" @click="reloadPage">
                  <i class="fa fa-refresh"></i>
                </button>
              </span>
              <span>
                <label for="" class="col-sm-auto">Count </label>
              </span>
              <span>
                <strong class="col-sm-auto">{{
                  formatPriceBasic(totalRows)
                }}</strong>
              </span>
            </b-col>
          </b-row>
          <TableBooks
            :books="magazines"
            :isLoading="isLoading"
            :currentPage="currentPageTransaction" 
            :perPage="perPageTransactions" 
            :reloadPage="reloadPage"
          />
        </div>
        <b-row class="justify-content-right ml-1">
          <b-col lg="3">
            <b-pagination
              v-model="currentPageTransaction"
              :total-rows="totalRows"
              :per-page="perPageTransactions"
              @input="onClickPage"
              aria-controls="my-table"
            />
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import TableBooks from '../../components/ebook/table.vue';
import { MagazineCreatePath } from '../../router/content';
import constant from '../../store/constant';

const ITEM_STATUS = Object.entries(constant.ITEM_STATUS).map((item) => {
  return { text: item[0], value: item[1] };
});
const { ROLES } = constant;

export default {
  name: 'magazine',
  components: {
    TableBooks,
  },
  data() {
    return {
      MagazineCreatePath,
      query_search: null,
      currentPageTransaction: 1,
      perPageTransactions: 50,
      vendor: [],
      vendorSearchOptions: [],
      isVendorSearch: false,
      status: null,
     statusOptions: [
        { text: '---Status [All]---', value: null },
        ...ITEM_STATUS,
      ],
      debounce: null,
      typing: false,
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        document.title =
          to.meta.title ||
          'Magazine | Content Management System Ebooks Gramedia.com';
      },
    },
  },
  created() {
    this.actionFirst();
  },
  computed: {
    ...mapGetters('auth', { auth: 'auth' }),
    ...mapGetters('magazine', ['magazines']),
    ...mapState({
      isLoading: (state) => state.magazine.isLoading,
      isError: (state) => state.magazine.isError,
      totalRows: (state) => state.magazine.totalRows,
    }),
  },
  methods: {
    ...mapActions('vendors', ['searchVendors']),
    ...mapActions('magazine', ['fetchMagazine', 'searchMagazine']),
    actionFirst() {
      const query = this.$route.query;
      this.currentPage = query.page ?? this.currentPage;
      this.perPage = query.limit ?? this.perPage;
      this.query_search = query.q ?? this.query_search;
      this.status = query.status ?? this.status;
      if (typeof(query.vendor_id) == "string") {
        const vendor = query.vendor_id.split("#");
        this.vendor = [{id: vendor[0], name: vendor[1]}];
      } else {
        this.vendor = (query.vendor_id ?? []).map(val => {
          const vendor = val.split("#");
          return {
            id: vendor[0],
            name: vendor[1],
          }
        });
      }
      this.reloadPage();
    },
    actionGetMagazines() {
      this.query_search = null;
      this.vendor = [];
      let payload = {
        page: this.currentPage,
        limit: this.perPage,
      };
      this.fetchMagazine(payload);
      this.setQuery(payload);
    },
    onSearch(event) {
      this.query_search = event.target.value;
      this.currentPage = 1;
      if (!this.query_search && !this.vendor.length) {
        this.actionGetMagazines();
        clearTimeout(this.debounce);
        return;
      }

      this.typing = true;
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.typing = false;
        this.actionSearchMagazine();
      }, 600);
    },
    actionSearchMagazine() {
      const payload = {
        q: this.query_search ?? '',
        page: this.currentPage,
        limit: this.perPage,
        vendor_id: this.vendor.map((val) => val.id),
        status: this.status,
      };
      this.searchMagazine(payload);
      payload.vendor_id = this.vendor.map((val) => `${val.id}#${val.name}`);
      this.setQuery(payload);
    },
    onClearSearch() {
      this.query_search = null;
      this.vendor = [];
      this.status = null;
      this.currentPage = 1;
      this.reloadPage();
    },
    onClickPage(page) {
      this.currentPage = page;
      this.reloadPage();
    },
    vendorFind(query) {
      if (!query) return;

      this.isVendorSearch = true;
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        let payload = {
          name: query,
          limit: 10,
        };
        this.searchVendors(payload)
          .then((response) => {
            this.vendorSearchOptions = response.data.data.rows;
            this.isVendorSearch = false;
          })
          .catch(() => {
            this.isVendorSearch = false;
          });
      }, 600);
    },
    reloadPage() {
      if (!this.query_search && !this.vendor.length && !this.status)
        this.actionGetMagazines();
      else this.actionSearchMagazine();
    },
    vendorChange() {
      this.currentPage = 1;
      this.reloadPage();
    },
    vendorRemove() {
      this.vendor = [];
      if (this.query_search) {
        this.actionSearchMagazine();
      } else {
        this.actionGetMagazines();
      }
    },
    statusChange() {
      this.reloadPage();
    },
    permFilterVendor() {
      switch (this.auth.role_id) {
        case ROLES.SUPER_ADMIN:
          return true;
        case ROLES.INTERNAL_ADMIN:
          return true;
        case ROLES.INTERNAL_DATA_ENTRY:
          return true;
        default:
          return false;
      }
    },
    formatPriceBasic(value) {
      let val = (value / 1).toFixed(0).replace('.', ',');
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    },
    setQuery(q = {}) {
      this.$router.push({ path: this.$route.path, state: q }).catch(() => {});
    }
  },
};
</script>

<style scoped>
.magazine {
  font-family: 'Avenir Next', 'effra', Helvetica, Arial, sans-serif;
}
</style>
